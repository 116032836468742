import React from "react"
import { navigate } from "@reach/router"
import { Link } from "gatsby"
import slugify from "slugify"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import blob from "../../assets/images/blogbob.svg"

import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles"
import { fade } from "@material-ui/core/styles/colorManipulator"
import Grid from "@material-ui/core/Grid"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Accordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Button from "@material-ui/core/Button"

import { FaArrowRight } from "react-icons/fa"

const primary = "#26a27b"
const dark = "#3a866e"

const Background = styled.div`
  background-image: url(${blob});
  background-size: auto;
  background-repeat: no-repeat;
  border-bottom: 2px solid ${primary};
  background-position: 0% 0%;
  color: white;
  padding: 4rem 0;
  text-align: center;
  @media only screen and (max-width: 1000px) {
    background-position: 50% 50%;
  }
`

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    color: `${dark}`,
    fontWeight: 500,
    fontSize: "2rem",
  },
  accordionSummary: {
    minHeight: 30,
    "& .Mui-expanded": {
      minHeight: 30,
    },
  },
  accordionHeading: {
    fontSize: "1.5rem",
    fontWeight: 500,
    color: `${primary}`,
  },
  locationRow: {
    "&:hover": {
      "& p": {
        color: `${primary}`,
      },
    },
  },
  countyText: {
    fontWeight: 500,
    fontSize: "1.25rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.1rem",
    },
  },
  btnCtr: {
    color: "white",
    "&:hover > a": {
      color: "white",
    },
  },
  viewMoreBtn: {
    borderRadius: 25,
    backgroundColor: `${primary}`,
    color: "white",
    textTransform: "capitalize",
    "&:hover": {
      color: "white",
      backgroundColor: fade(`${primary}`, 0.9085),
      "& svg": {
        transform: "translateX(5px)",
      },
    },
  },
  faIconArrow: {
    color: "white",
    marginLeft: 5,
    transition: ".5s",
    "&:hover": {
      color: "white",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 4,
    },
  },
}))
const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary)

export default function ServiceAreasIndex({ data }) {
  const classes = useStyles()
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  const {
    allContentfulCountyServiceArea: { nodes: counties },
    allContentfulCityServiceArea: { nodes: cities },
    allContentfulStateServiceArea: { nodes: states },
    // site: { siteMetadata: siteUrl },
  } = useStaticQuery(allServiceLocationsQuery)

  return (
    <Layout>
      <Seo
        title="Locations"
        description="Service area locations of Snappy Web Design."
        // image={`${siteUrl.siteUrl}/images/blog.jpg`}
        // @! TODO
        // @image
        // @description
        // @title of slug 'Top Web Designer in x'
      />
      <Background>
        <h1
          style={{
            color: `${primary}`,
          }}
        >
          Local
          <br /> Expert Web Design
        </h1>
      </Background>

      <Container style={{ maxWidth: 720 }}>
        <Grid
          container
          justify="center"
          style={{ paddingTop: "4em", paddingBottom: "6.25em" }}
        >
          <Grid item xs={12} style={{ paddingBottom: "2em" }}>
            <Typography
              variant="h2"
              align="center"
              className={classes.pageTitle}
            >
              Service Area Locations
            </Typography>
            <Divider
              style={{
                width: "55%",
                marginLeft: "auto",
                marginRight: "auto",
                backgroundColor: "rgba(38,162,123,0.5)",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item xs={10}>
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.accordionSummary}
                  >
                    <Typography className={classes.accordionHeading}>
                      Counties
                    </Typography>
                  </AccordionSummary>
                  <Divider />
                  {counties.map((county) => {
                    const slug = slugify(county.url, { lower: true })
                    return (
                      <React.Fragment key={county.id}>
                        <AccordionDetails
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(`/locations/${slug}/`)}
                          className={classes.locationRow}
                        >
                          <Grid container>
                            <Grid item xs={8} style={{ position: "relative" }}>
                              <Typography
                                component={Link}
                                to={`/locations/${slug}/`}
                                title={`${county.county} Website Design`}
                                className={classes.countyText}
                                style={{
                                  position: "absolute",
                                  top: "20%",
                                  textDecoration: "none",
                                  color: "rgba(0,0,0,.87)",
                                }}
                              >
                                {county.county}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Grid
                                container
                                justify="center"
                                alignItems="center"
                                className={classes.btnCtr}
                              >
                                <Button
                                  className={classes.viewMoreBtn}
                                  variant="contained"
                                  size={matchesXS ? "small" : "medium"}
                                  component={Link}
                                  to={`/locations/${slug}/`}
                                >
                                  {matchesXS ? "More" : "View More"}{" "}
                                  <FaArrowRight
                                    size={matchesXS ? 15 : 20}
                                    className={classes.faIconArrow}
                                  />
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                        <Divider variant="middle" />
                      </React.Fragment>
                    )
                  })}
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "2em" }}>
            <Grid container justify="center">
              <Grid item xs={10}>
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={classes.accordionHeading}>
                      Cities
                    </Typography>
                  </AccordionSummary>
                  <Divider />
                  {cities.map((city) => {
                    const slug = slugify(city.url, { lower: true })
                    return (
                      <React.Fragment key={city.id}>
                        <AccordionDetails
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(`/locations/${slug}/`)}
                          className={classes.locationRow}
                        >
                          <Grid container>
                            <Grid item xs={8} style={{ position: "relative" }}>
                              <Typography
                                component={Link}
                                to={`/locations/${slug}/`}
                                title={`${city.city} Website Design`}
                                className={classes.countyText}
                                style={{
                                  position: "absolute",
                                  top: "20%",
                                  textDecoration: "none",
                                  color: "rgba(0,0,0,.87)",
                                }}
                              >
                                {city.city}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Grid
                                container
                                justify="center"
                                alignItems="center"
                                className={classes.btnCtr}
                              >
                                <Button
                                  className={classes.viewMoreBtn}
                                  variant="contained"
                                  size={matchesXS ? "small" : "medium"}
                                  component={Link}
                                  to={`/locations/${slug}/`}
                                >
                                  {matchesXS ? "More" : "View More"}{" "}
                                  <FaArrowRight
                                    size={matchesXS ? 15 : 20}
                                    className={classes.faIconArrow}
                                  />
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                        <Divider variant="middle" />
                      </React.Fragment>
                    )
                  })}
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "2em" }}>
            <Grid container justify="center">
              <Grid item xs={10}>
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                  >
                    <Typography className={classes.accordionHeading}>
                      States
                    </Typography>
                  </AccordionSummary>
                  <Divider />
                  {states.map((state) => {
                    const slug = slugify(state.url, { lower: true })
                    return (
                      <React.Fragment key={state.id}>
                        <AccordionDetails
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(`/locations/${slug}/`)}
                          className={classes.locationRow}
                        >
                          <Grid container>
                            <Grid item xs={8} style={{ position: "relative" }}>
                              <Typography
                                component={Link}
                                to={`/locations/${slug}/`}
                                title={`${state.state} Website Design`}
                                className={classes.countyText}
                                style={{
                                  position: "absolute",
                                  top: "20%",
                                  textDecoration: "none",
                                  color: "rgba(0,0,0,.87)",
                                }}
                              >
                                {state.state}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Grid
                                container
                                justify="center"
                                alignItems="center"
                                className={classes.btnCtr}
                              >
                                <Button
                                  className={classes.viewMoreBtn}
                                  variant="contained"
                                  size={matchesXS ? "small" : "medium"}
                                  component={Link}
                                  to={`/locations/${slug}/`}
                                >
                                  {matchesXS ? "More" : "View More"}{" "}
                                  <FaArrowRight
                                    size={matchesXS ? 15 : 20}
                                    className={classes.faIconArrow}
                                  />
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                        <Divider variant="middle" />
                      </React.Fragment>
                    )
                  })}
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export const allServiceLocationsQuery = graphql`
  {
    allContentfulCityServiceArea(sort: { fields: createdAt, order: ASC }) {
      nodes {
        id
        url
        city
        fullLocation
        zipCodes
      }
    }
    allContentfulCountyServiceArea(sort: { fields: createdAt, order: ASC }) {
      nodes {
        id
        url
        county
        fullLocation
        zipCodes
      }
    }
    allContentfulStateServiceArea(sort: { fields: createdAt, order: ASC }) {
      nodes {
        id
        url
        state
        fullLocation
        zipCodes
      }
    }
  }
`
